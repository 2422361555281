import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from './shared/Header';
import Footer from './shared/Footer';

const navigation = [
  { name: 'Methodology', href: '/#methodology' },
  { name: 'About', href: '/#about' },
  { name: 'Power List™', href: '/power-list' },
  { name: 'Fact Sheets', href: '/#factsheets' },
  { name: 'Pricing', href: '/#pricing' },
  { name: 'Contact', href: '/#contact' },
];

export default function PowerListPage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [powerListData, setPowerListData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPowerList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/ea_stock_rankings?type=ea_power_list`);
        setPowerListData(response.data);
      } catch (error) {
        console.error('Error fetching power list:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPowerList();
  }, []);

  const handleSecurityClick = (security) => {
    navigate('/login', { 
      state: { message: 'Log in to view detailed security information' }
    });
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex justify-center items-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
      </div>
    );
  }

  const previewList = powerListData?.rankings.slice(0, 20) || [];
  const totalStocks = powerListData?.rankings.length || 0;
  const remainingStocks = totalStocks - 20;

  return (
    <>
      <Header />
      <div className="min-h-screen bg-gray-900">
        <main className="relative isolate">
          {/* Background */}
          <div
            className="absolute inset-x-0 top-4 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
              style={{
                clipPath:
                  'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
              }}
            />
          </div>

          {/* Header Section */}
          <div className="px-6 pt-14 lg:px-8">
            <div className="mx-auto max-w-2xl pt-24 text-center sm:pt-40">
              <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                Extract Alpha Power List™
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Our proprietary ranking methodology combines fundamental analysis, market sentiment, 
                and alternative data to identify the most promising investment opportunities. 
                Updated monthly, this list represents our highest-conviction stock selections 
                based on our systematic approach to market analysis.
              </p>
            </div>
          </div>

          {/* Power List Preview Table */}
          <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-16">
            <div className="bg-gray-800/50 rounded-lg shadow p-4">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h3 className="text-base font-semibold leading-6 text-white">
                    Current Power List Preview
                  </h3>
                  {powerListData?.as_of_date && (
                    <p className="mt-2 text-sm text-gray-400">
                      As of {new Date(powerListData.as_of_date).toLocaleDateString()}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-8 flow-root">
                <div className="overflow-x-auto">
                  <div className="inline-block min-w-full align-middle">
                    <table className="min-w-full divide-y divide-gray-700">
                      <thead>
                        <tr>
                          <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">Rank</th>
                          <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">Ticker</th>
                          <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">Company Name</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-800">
                        {previewList.map((stock) => (
                          <tr 
                            key={stock.security_id}
                            onClick={() => handleSecurityClick(stock)}
                            className="hover:bg-gray-700/50 cursor-pointer"
                          >
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {stock.ranking}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {stock.ticker}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {stock.name}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* Login CTA Section */}
              <div className="mt-8 bg-indigo-500/10 rounded-lg p-8 text-center">
                <h4 className="text-xl font-semibold text-white mb-4">
                  Want to see the complete Power List?
                </h4>
                <p className="text-gray-300 mb-6">
                  Log in to view all {remainingStocks} additional stocks and get access to detailed analytics.
                </p>
                <div className="flex justify-center gap-4">
                  <a
                    href="/login"
                    className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Log In
                  </a>
                  <a
                    href="/register"
                    className="rounded-md bg-white/10 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                  >
                    Register
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Section */}
          <div className="mt-32 sm:mt-40">
            <div className="relative isolate">
              <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
                  <div className="w-full flex-auto">
                    <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                      Want to learn more?
                    </h2>
                    <p className="mt-6 text-lg leading-8 text-gray-300">
                      Contact us to discover how our systematic approach and alternative data insights 
                      can enhance your investment strategy.
                    </p>
                    <div className="mt-10 flex">
                      <a
                        href="mailto:info@extractalpha.com"
                        className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
} 