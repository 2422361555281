import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function TickerPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { security } = location.state || {};

  if (!security) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-900">
      <div className="mt-5 px-4 sm:px-6 lg:px-8">
        <button
          onClick={() => navigate(-1)}
          className="mb-4 flex items-center text-sm text-gray-400 hover:text-white"
        >
          <svg 
            className="mr-2 h-4 w-4" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M10 19l-7-7m0 0l7-7m-7 7h18" 
            />
          </svg>
          Back
        </button>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold leading-6 text-white">{security.name}</h1>
            <p className="mt-2 text-sm text-gray-400">CUSIP: {security.cusip}</p>
          </div>
        </div>
      </div>
    </div>
  );
} 