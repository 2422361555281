import React from 'react';

const navigation = [
  { name: 'Methodology', href: '#methodology' },
  { name: 'About', href: '#about' },
  { name: 'Power List™', href: '/power-list' },
  { name: 'Fact Sheets', href: '#factsheets' },
  { name: 'Pricing', href: '#pricing' },
  { name: 'Contact', href: '#contact' },
];

export default function Footer() {
  return (
    <footer className="bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <img alt="ExtractAlpha" src="https://extractalpha.com/wp-content/uploads/2023/02/extractalpha-white.svg?color=indigo&shade=500" className="h-7" />
            <p className="text-sm leading-6 text-gray-300">© 2024 ExtractAlpha. All rights reserved.</p>
            <div className="flex space-x-6">
              <a href="https://www.linkedin.com/company/extractalpha" className="text-gray-500 hover:text-gray-400">
                <span className="sr-only">LinkedIn</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M20.447 20.452H17.2v-5.553c0-1.323-.474-2.229-1.66-2.229-0.905 0-1.444 0.606-1.68 1.191-0.087 0.213-0.108 0.512-0.108 0.812v5.779H9.908s0.049-9.39 0-10.355h3.248v1.467c-0.007 0.011-0.018 0.023-0.026 0.034h0.026v-0.034c0.432-0.667 1.204-1.62 2.932-1.62 2.139 0 3.74 1.391 3.74 4.387v6.122zM7.231 7.548c-1.112 0-1.836 0.73-1.836 1.685 0 0.938 0.707 1.684 1.796 1.684h0.022c1.127 0 1.834-0.746 1.834-1.684-0.022-0.955-0.707-1.685-1.816-1.685zM8.668 20.452H5.793V10.097h2.875v10.355zM22.225 0H1.771C0.79 0 0 0.775 0 1.728v20.542C0 23.225 0.79 24 1.771 24h20.451C23.205 24 24 23.225 24 22.271V1.728C24 0.775 23.205 0 22.225 0z" />
                </svg>
              </a>
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div>
              <h3 className="text-sm font-semibold leading-6 text-white">Quick Links</h3>
              <ul role="list" className="mt-6 space-y-4">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold leading-6 text-white">Legal</h3>
              <ul role="list" className="mt-6 space-y-4">
                <li>
                  <a href="#" className="text-sm leading-6 text-gray-300 hover:text-white">Privacy Policy</a>
                </li>
                <li>
                  <a href="#" className="text-sm leading-6 text-gray-300 hover:text-white">Terms of Service</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-400">&copy; 2024 ExtractAlpha. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
} 