import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

export default function PrivateRoute({ children }) {
  const { user, loading } = useAuth();
  const location = useLocation();

  // Add debugging logs
  console.log('PrivateRoute - Current user:', user);
  console.log('PrivateRoute - Loading:', loading);
  console.log('PrivateRoute - Current location:', location);

  // Don't redirect while still loading
  if (loading) {
    return null; // or return a loading spinner
  }

  if (!user) {
    console.log('PrivateRoute - Redirecting to login because user is not authenticated');
    // Redirect to login with the attempted URL in state
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  console.log('PrivateRoute - User authenticated, rendering protected content');
  return children;
} 