import React, { useEffect, useState } from 'react';
import { 
  ArrowPathIcon, CloudArrowUpIcon, LockClosedIcon, 
  Cog6ToothIcon, FingerPrintIcon, CheckIcon 
} from '@heroicons/react/20/solid';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { RadioGroup, Radio} from '@headlessui/react';
import Header from './shared/Header';
import Footer from './shared/Footer';

const features = [
  {
    name: 'Data Collection',
    description: 'We use diverse and high-quality alternative data sources to gain unique insights, including earnings estimates, cross asset, transcripts, news, social media sentiment, web traffic, and more',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Algorithmic Analysis',
    description: 'Advanced algorithms process data to identify market trends and opportunities',
    icon: LockClosedIcon,
  },
  {
    name: 'Portfolio Construction',
    description: 'Our portfolios are diverse in nature and meticulously constructed to balance risk and return based on the latest data-driven insights, aimed at maximizing advisor return while managing risk',
    icon: ArrowPathIcon,
  },
  {
    name: 'Ongoing Optimization',
    description: 'Continuous monitoring and adjustment ensure alignment with market conditions and client objectives',
    icon: FingerPrintIcon,
  },
  {
    name: 'Visuals',
    description: 'Infographic or flowchart of the methodology process',
    icon: Cog6ToothIcon,
  }
];


const frequencies = [
  { value: 'quarterly', label: 'Quarterly', priceSuffix: '/quarter' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
];

const tiers = [
  {
    name: 'Professional Plan',
    id: 'tier-professional',
    href: '/register',
    price: { quarterly: '$1,797', annually: '$5,990' },
    description: 'Enhanced data analytics and portfolio options.',
    features: [
      'Enhanced data analytics',
      'Additional portfolio options',
      'Rebalancing',
      'Essential data insights'
    ],
    mostPopular: false,
  },
  {
    name: 'Premium Plan',
    id: 'tier-premium',
    href: '/register',
    price: { quarterly: '$2,397', annually: '$7,990' },
    description: 'Full access to all portfolios, advanced tools, and dedicated support.',
    features: [
      'Full access to all portfolios',
      'Advanced data tools',
      'Dedicated support',
      'Rebalancing',
      'Essential data insights'
    ],
    mostPopular: true,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ModelsTable() {
  const [models, setModels] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/models`)
      .then(response => {
        setModels(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the models!', error);
      });
  }, []);

  const handleRowClick = (id) => {
    navigate('/login');
  };

  return (
    <div className="px-4 sm:px-6 lg:px-12 py-12 bg-gray-900">
      <div className="mt-8">
        <div className="mx-auto max-w-7xl">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Model Performance</h2>
          <div className="mt-4 text-gray-300 space-y-2">
            <ul className="list-disc list-inside">
              <li>Data-driven investment strategies</li>
              <li>Systematic portfolio construction</li>
              <li>Risk-managed approach</li>
              <li>Regular rebalancing</li>
            </ul>
          </div>
          <br />
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-x-auto">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg bg-gray-800">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead className="bg-gray-800">
                    <tr>
                      <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Model Name</th>
                      <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Description</th>
                      <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Annual Return (%)</th>
                      <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Sharpe Ratio</th>
                      <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Excess Return (%)</th>
                      <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white">Monthly Turnover (%)</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700 bg-gray-800">
                    {models.map((model) => (
                      <tr key={model.id} onClick={() => handleRowClick(model.id)} className="cursor-pointer">
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{model.model_name}</td>
                        <td className="whitespace-normal px-2 py-2 text-sm text-gray-300">{model.model_description}</td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{(model.ann_ret * 100).toFixed(2)}%</td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{model.sharpe}</td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{(model.excess_ret * 100).toFixed(2)}%</td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-300">{(model.month_turnover * 100).toFixed(2)}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function LandingPage() {
  const [frequency, setFrequency] = useState(frequencies[0]);

  const navigate = useNavigate();

  const handleRowClick = (id) => {
    navigate('/login'); // Navigate to the login page
  };


  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.async = true;
    script.onload = () => {
      window.hbspt.forms.create({
        portalId: "22063250",
        formId: "2b48c885-655a-40e1-9d30-0fb8e6894aac",
        target: "#hubspotForm" // Target the placeholder div
      });
    };
    document.body.appendChild(script);
    
    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Header />
      <div id="home" className="relative isolate overflow-hidden bg-gray-900">
        <div aria-hidden="true" className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]">
          <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
            <path d="M-200 0h201v201h-201ZM600 0h201v201h-201ZM-400 600h201v201h-201ZM200 800h201v201h-201Z" strokeWidth={0} />
          </svg>
          <rect fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" width="100%" height="100%" strokeWidth={0} />
        </div>
        <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
          <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
            <div className="mt-24 sm:mt-32 lg:mt-16">
              {/* Removed What's new section */}
            </div>
            <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Access Data Driven Investment Strategies with Extract Alpha's Model Portfolios for Advisors
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">Empower your clients with cutting-edge alternative data insights.</p>
            <div className="mt-10 flex items-center gap-x-6">
              <a href="#factsheets" className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">
                Explore Our Portfolios
              </a>
            </div>
          </div>
          <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
              <img alt="App screenshot" src="/screenshot.png" width={2432} height={1442} className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10" />
            </div>
          </div>
        </div>
      </div>

      <div id="home" className="bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Transform Your Investment Approach</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              At Extract Alpha, we leverage sophisticated alternative data to craft model portfolios that deliver superior results. Designed purely for Registered Investment Advisors and wealth managers, our portfolios are engineered to meet the evolving needs of your clients.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a href="/register" className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="methodology" className="bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Our Methodology</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">Our model portfolios are grounded in rigorous research and innovative data analysis. Here's how we create value for our Advisors:</p>
          </div>
          <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="inline font-semibold text-white">
                  <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-indigo-500" />
                  {feature.name}
                </dt>{' '}
                <dd className="inline">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>

      <div id="about" className="bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">About Extract Alpha</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Extract Alpha is a leader in alternative data solutions for investment professionals. Our team of experts is dedicated to harnessing the power of data to enhance investment strategies and deliver unparalleled results for Registered Investment Advisors and Wealth Managers.
            </p>
            <div className="mt-10 flex flex-col gap-6">
              <div>
                <h3 className="text-2xl font-bold text-white">Our Vision</h3>
                <p className="mt-2 text-lg text-gray-300">To revolutionize investment decision-making for advisors with alternative data for model portfolios.</p>
              </div>
              <div>
                <h3 className="text-2xl font-bold text-white">Our Team</h3>
                <p className="mt-2 text-lg text-gray-300">Meet the experts behind our innovative solutions.</p>
              </div>
              <div>
                <h3 className="text-2xl font-bold text-white">Our History</h3>
                <p className="mt-2 text-lg text-gray-300">Learn about our journey and milestones.</p>
              </div>
            </div>
            <div className="mt-10 flex items-center gap-x-6">
              <a href="#contact" className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>

      <ModelsTable />

      <div id="factsheets" className="bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Model Portfolio Factsheets</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Explore detailed factsheets for each of our model portfolios. Each factsheet provides comprehensive information about the portfolio's strategy, performance, and risk profile.
            </p>
            <div className="mt-10 flex flex-col gap-6 overflow-x-auto">
              <div className="bg-white/5 p-4 rounded-lg" onClick={() => handleRowClick(1)}>
                <h3 className="text-xl font-bold text-white">ExtractAlpha S&P 500 Long Only</h3>
                <p className="mt-2 text-lg text-gray-300">Pure Alpha Model Portfolio. Combination of Momentum /Value, Truebeats(Value) + Peer Momentum. Monthly Rebalance.</p>
                <a href="/models" className="mt-2 text-indigo-400 hover:text-indigo-300">Go to the model</a>
              </div>
              <div className="bg-white/5 p-4 rounded-lg" onClick={() => handleRowClick(2)}>
                <h3 className="text-xl font-bold text-white">ExtractAlpha Russell 1000 Long Only</h3>
                <p className="mt-2 text-lg text-gray-300">Pure Alpha Model Portfolio. Combination of Momentum /Value, Truebeats(Value) + Peer Momentum. Monthly Rebalance.</p>
                <a href="/models" className="mt-2 text-indigo-400 hover:text-indigo-300">Go to the model</a>
              </div>
              <div className="bg-white/5 p-4 rounded-lg" onClick={() => handleRowClick(3)}>
                <h3 className="text-xl font-bold text-white">ExtractAlpha Top 2000 Long Only</h3>
                <p className="mt-2 text-lg text-gray-300">Pure Alpha Model Portfolio. Combination of Momentum /Value, Truebeats(Value) + Peer Momentum. Monthly Rebalance.</p>
                <a href="/models" className="mt-2 text-indigo-400 hover:text-indigo-300">Go to the model</a>
              </div>
              <div className="bg-white/5 p-4 rounded-lg" onClick={() => handleRowClick(4)}>
                <h3 className="text-xl font-bold text-white">ExtractAlpha Russell 1000 Enhanced</h3>
                <p className="mt-2 text-lg text-gray-300">Portfolio based on changing existing constituents.</p>
                <a href="/models" className="mt-2 text-indigo-400 hover:text-indigo-300">Go to the model</a>
              </div>
            </div>
            <div className="mt-10 flex items-center gap-x-6">
              <a href="#contact" className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">
                Request a Custom Portfolio
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="pricing" className="bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-400">Pricing</h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">Flexible Pricing Plans</p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
            We offer various pricing plans to suit the needs of different investment professionals. Unlike TAMPs and other model portfolio providers, we do not charge basis points, keeping costs low for our advisor community.
          </p>
          <div className="mt-16 flex justify-center">
            <RadioGroup value={frequency} onChange={setFrequency} className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white">
              {frequencies.map((option) => (
                <Radio key={option.value} value={option} className="cursor-pointer rounded-full px-2.5 py-1 data-[checked]:bg-indigo-500">
                  {option.label}
                </Radio>
              ))}
            </RadioGroup>
          </div>
          <div className="mx-auto mt-10 flex justify-center gap-8">
            {tiers.map((tier) => (
              <div 
                key={tier.id} 
                className={classNames(
                  tier.mostPopular ? 'bg-white/5 ring-2 ring-indigo-500' : 'ring-1 ring-white/10',
                  'rounded-3xl p-8 xl:p-10 w-[400px]'
                )}
              >
                <div className="flex items-center justify-between gap-x-4">
                  <h3 id={tier.id} className="text-lg font-semibold leading-8 text-white">{tier.name}</h3>
                  {tier.mostPopular ? (
                    <p className="rounded-full bg-indigo-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">Most popular</p>
                  ) : null}
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-300">{tier.description}</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-white">{tier.price[frequency.value]}</span>
                  <span className="text-sm font-semibold leading-6 text-gray-300">{frequency.priceSuffix}</span>
                </p>
                <a href={tier.href} aria-describedby={tier.id} className={classNames(tier.mostPopular ? 'bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500' : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white', 'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2')}>
                  Get Started
                </a>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-white" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div id="contact" className="relative isolate bg-gray-900">
        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
          <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
              <h2 className="text-3xl font-bold tracking-tight text-white">Get in touch</h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">Have questions or need more information? Our team is here to help you. Reach out to us today.</p>
              <div id="hubspotForm" className="mt-10" /> {/* Placeholder for HubSpot form */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
