import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const navigation = [
  { name: 'Methodology', href: '/#methodology' },
  { name: 'About', href: '/#about' },
  { name: 'Power List™', href: '/power-list' },
  { name: 'Fact Sheets', href: '/#factsheets' },
  { name: 'Pricing', href: '/#pricing' },
  { name: 'Contact', href: '/#contact' },
];

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const { user, logout } = useAuth();

  // Function to handle navigation
  const handleNavigation = (e, href) => {
    // If it's a section link and we're not on the landing page
    if (href.startsWith('/#') && location.pathname !== '/') {
      e.preventDefault();
      window.location.href = href; // Force a full navigation to the landing page section
    }
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await logout();
      window.location.href = '/';
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <header className="bg-gray-900">
      <nav aria-label="Global" className="mx-auto max-w-7xl flex items-center justify-between p-6 lg:px-8">
        <div className="flex lg:flex-1">
          <a href="https://extractalpha.com" className="-m-1.5 p-1.5">
            <span className="sr-only">ExtractAlpha Model Portfolios for Advisors</span>
            <img alt="ExtractAlpha" src="https://extractalpha.com/wp-content/uploads/2023/02/extractalpha-white.svg?color=indigo&shade=500" className="h-8 w-auto" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button type="button" onClick={() => setMobileMenuOpen(true)} className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400">
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              onClick={(e) => handleNavigation(e, item.href)}
              className="text-sm font-semibold leading-6 text-gray-300 hover:text-white"
            >
              {item.name}
            </a>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {!user ? (
            <>
              <a href="/register" className="text-sm font-semibold leading-6 text-white mr-8">
                Register <span aria-hidden="true"></span>
              </a>
              <a href="/login" className="text-sm font-semibold leading-6 text-white">
                Log in <span aria-hidden="true">&rarr;</span>
              </a>
            </>
          ) : (
            <>
              <a href="/models" className="text-sm font-semibold leading-6 text-white mr-8">
                Dashboard
              </a>
              <button
                onClick={handleLogout}
                className="text-sm font-semibold leading-6 text-white"
              >
                Log out <span aria-hidden="true">&rarr;</span>
              </button>
            </>
          )}
        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          <div className="flex items-center justify-between">
            <a href="https://extractalpha.com" className="-m-1.5 p-1.5">
              <span className="sr-only">ExtractAlpha</span>
              <img alt="ExtractAlpha" src="https://extractalpha.com/wp-content/uploads/2023/02/extractalpha-white.svg?color=indigo&shade=500" className="h-8 w-auto" />
            </a>
            <button type="button" onClick={() => setMobileMenuOpen(false)} className="-m-2.5 rounded-md p-2.5 text-gray-400">
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/25">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    onClick={(e) => handleNavigation(e, item.href)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                {!user ? (
                  <>
                    <a href="/register" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800">
                      Register
                    </a>
                    <a href="/login" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800">
                      Log in
                    </a>
                  </>
                ) : (
                  <>
                    <a href="/models" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800">
                      Dashboard
                    </a>
                    <button
                      onClick={handleLogout}
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800 w-full text-left"
                    >
                      Log out
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
} 