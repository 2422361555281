import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function FullPowerListPage() {
  const [powerListData, setPowerListData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPowerList = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/ea_stock_rankings?type=ea_power_list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPowerListData(response.data);
      } catch (error) {
        console.error('Error fetching power list:', error);
        if (error.response?.status === 401) {
          navigate('/login');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPowerList();
  }, [navigate]);

  const handleSecurityClick = (security) => {
    navigate(`/ticker/${security.security_id}`, { 
      state: { security }
    });
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex justify-center items-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 px-4 sm:px-6 lg:px-8 py-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold leading-6 text-white">Extract Alpha Power List™</h1>
          <p className="mt-2 text-sm text-gray-400">
            Our highest-conviction stock selections based on our proprietary ranking methodology.
          </p>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="bg-gray-800/50 rounded-lg shadow-xl overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            {powerListData?.as_of_date && (
              <div className="mb-4 text-sm text-gray-400">
                Last updated: {new Date(powerListData.as_of_date).toLocaleDateString()}
              </div>
            )}
            
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-700">
                <thead>
                  <tr>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">Rank</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">Ticker</th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">Company Name</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800">
                  {powerListData?.rankings.map((stock) => (
                    <tr 
                      key={stock.security_id}
                      onClick={() => handleSecurityClick(stock)}
                      className="hover:bg-gray-700/50 cursor-pointer"
                    >
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                        {stock.ranking}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                        {stock.ticker}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                        {stock.name}
                      </td>
                   
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Enterprise CTA Section */}
      <div className="mt-8">
        <div className="rounded-lg bg-gradient-to-r from-indigo-500/10 to-blue-500/10 p-8">
          <div className="mx-auto max-w-md text-center">
            <h2 className="text-xl font-semibold text-white">
              Want Access to Our Complete Rankings?
            </h2>
            <p className="mt-2 text-gray-300">
              Get access to our comprehensive rankings for over 25,000 securities, including detailed analytics,score breakdowns, 
              real-time updates, and custom screening tools.
            </p>
            <div className="mt-4">
              <a
                href="mailto:info@extractalpha.com?subject=Enterprise%20Access%20Inquiry"
                className="inline-flex items-center rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Contact Us for Enterprise Access
                <span className="ml-2" aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 