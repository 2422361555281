import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Sidebar from './components/Sidebar';
import ModelsTable from './components/ModelsTable';
import ModelPage from './components/ModelPage';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import LandingPage from './components/LandingPage';
import TickerPage from './components/TickerPage';
import PowerListPage from './components/PowerListPage';
import FullPowerListPage from './components/FullPowerListPage';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <AuthProvider>
      <Router>
        <MainLayout />
      </Router>
    </AuthProvider>
  );
}

function MainLayout() {
  const location = useLocation();
  const noSidebarPaths = ['/login', '/register', '/', '/power-list'];

  const showSidebar = !noSidebarPaths.includes(location.pathname);

  return (
    <div className="flex flex-col h-screen bg-gray-900">
      {showSidebar && <Sidebar />}
      <div className={`flex flex-col flex-1 ${showSidebar ? 'lg:ml-72' : ''}`}>
        <main className="flex-grow overflow-y-auto">
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/power-list" element={<PowerListPage />} />

            {/* Protected routes */}
            <Route path="/model/:id" element={
              <PrivateRoute>
                <ModelPage />
              </PrivateRoute>
            } />
            <Route path="/models" element={
              <PrivateRoute>
                <ModelsTable />
              </PrivateRoute>
            } />
            <Route path="/ticker/:cusip" element={
              <PrivateRoute>
                <TickerPage />
              </PrivateRoute>
            } />
            <Route path="/rankings/power-list" element={
              <PrivateRoute>
                <FullPowerListPage />
              </PrivateRoute>
            } />
          </Routes>
        </main>
        <footer>
          {/* Your footer content */}
        </footer>
      </div>
    </div>
  );
}

export default App;
